.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;

  .navigationHeading {
    .heading5;

    @media screen and (min-width: @screen-lg) {
      .heading6;
    }
  }

  .navigationParagraph {
    .paragraph;

    @media screen and (min-width: @screen-lg) {
      .paragraph--s;
    }
  }

  ul {
    list-style-type: none;
    margin-bottom: 0;
    .sitePadding();
  }

  &.menuLevel-1,
  &.menuLevel-2 {
    .navigationActionBar {
      @media screen and (min-width: @screen-lg) {
        justify-content: end;

        button:first-child {
          display: none;
        }
      }
    }
  }

  .navigationItem {
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    padding: 1.2rem 0;
    width: 100%;

    button {
      padding: 0;
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: @screen-md-max) {
      text-decoration: none !important;
    }

    &:not(.skipHover):hover {
      text-decoration: underline;
      text-underline-offset: 0.4rem;
    }
  }

  .menuFirstLevelBottom {
    margin-top: @spacer9;

    .navigationBorder {
      padding-top: @spacer8;
      border-top: 0.1rem solid @Gray20;
    }
    li {
      width: 100%;
      display: flex;
      column-gap: @spacer3;
      align-items: center;
      color: @Gray80;

      i {
        background: @Gray80;
      }
    }
  }

  .navigationBottom {
    margin-top: @spacer7;
    padding-top: @spacer7;
    padding-bottom: @spacer20;
    height: 100%;

    &.bg-color--0 {
      background-color: @SoftSand25;
    }

    &.bg-color--1 {
      background-color: @NutriaGreen25;
    }

    &.bg-color--2 {
      background-color: @Accent2_50;
    }

    &.bg-color--3 {
      background-color: @MistGreen50;
    }
  }
}
