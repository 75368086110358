.primaryDesktopNavigation {
    height: 100%;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;

        li button,
        li a {
            height: 100%;
            display: flex;
            align-items: center;
            padding: @spacer4;

            &:focus-visible {
                outline: 1px @Gray80 solid;
            }
        }

        li a {
            line-height: 0;
        }
    }

    @media screen and (min-width: @screen-lg-min) {
        flex-shrink: 0;
    }

    .navigation__item {
        padding: @spacer4;
        position: relative;

        .navigationHeading {
            position: relative;
            text-decoration: none;
            transition: color .5s ease; 
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: .1rem;
                background-color: @Black;
                opacity: 0;
                transition: opacity 1s ease;
            }
        }

        &.active .navigationHeading::after {
            opacity: 1;
        }

        &:not(.active):hover {
            color: @Gray70;

            .navigationHeading::after {
                opacity: 1;
                background-color: @Gray70;
            }
        }
    }
}