.quickLinks {
    display: flex;
    align-items: center;

    .cart-counter {
        align-items: center;
        background-color: @PitchBlack;
        border-radius: 50%;
        color: @White;
        display: flex;
        font-size: 10px;
        height: 2rem;
        justify-content: center;
        left: 23px;
        position: absolute;
        top: -8px;
        width: 2rem;
        z-index: 1;

        &:empty {
            display: none;
        }
    }

    .quickLinks__btn {
        position: relative;
        line-height: 0;
        padding: 0 1rem;
    }

    &.hide {
        .quickLinks__btn:not(.exit) {
            @media screen and (max-width: @screen-md-max) {
                display: none;
            }
        }
    }
}