.linkSection.images {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding-bottom: @spacer9;
    margin-top: @spacer7;

    li {
        flex-basis: calc(50% - 1rem);
        max-width: calc(50% - 1rem);
        flex-shrink: 0;

        &:empty {
            display: none;
        }

        a {
            display: flex;
            flex-direction: column;

            img {
                aspect-ratio: 1 / 1;
                margin-bottom: .6rem;
                width: 100%;
                height: 100%;
                background-color: @Gray10;
                object-fit: cover;

                &::before {
                    display: none;
                }
            }
        }
    }
}