@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.menuLevel-1 .promotion {
    @media screen and (max-width: @screen-md-max) {
        display: none;
    }
}

.promotion {
    margin-top: @spacer9;

    .promotion__image {
        position: relative;
        overflow: hidden;
        display: block;

        img {
            width: 100%;
        }

        .shadowOverlay {
            background-color: #000;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 1;
            opacity: 0.3;
            pointer-events: none;
        }

        .promotion__header {
            position: absolute;
            color: @White;
            z-index: 2;
            left: 0;
            bottom: @spacer6;

            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: end;
            text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.6);

            @media screen and (max-width: @screen-md-max) {
                bottom: @spacer8;
            }

            i {
                background: @White;
            }
        }
    }
}