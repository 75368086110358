@slideOutEffect: width 0.3s ease, visibility 0s, opacity 0.3s ease;

.siteHeader_search {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    height: 100%;
    position: relative;
    justify-content: end;

    button {
        line-height: 0;
    }

    input {
        width: 100%;
        border: none;

        &:focus {
            outline: none;
        }
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: .1rem;
        background-color: @Black;
        opacity: 0.2;
    }

    @media screen and (max-width: @screen-md-max) {
        flex-grow: 1;

        &::after{
            visibility: hidden;
            width: 0;
        }

        &.active {
            input {
                width: 100%;
                visibility: visible;
                opacity: 1;
                transition: @slideOutEffect;
            }

            &::after {
                visibility: visible;
                width: 100%;
                transition: @slideOutEffect;
            }
        }

        &:not(.active) input {
            width: 0;
            visibility: hidden;
            opacity: 0;
            transition: width 0.3s ease, visibility 0s 0.3s, opacity 0.3s ease;
        }
    }
}