@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';
@import '/Foundation/Styling/code/thule/global/frg-icons.less';
@import '/Project/CaseLogic/code/CaseLogic/icons/cl-icons.less';


a.logo {
    display: block;
    flex: 0;
    color: @Black;
    z-index: 2;

    @media screen and (min-width: @screen-lg) {
        z-index: 3;
        display: flex;
        align-items: center;
        margin-right: 4.3rem;
    }

    i.logotype {
        font-size: @LogoTypeMobileSize;

        @media screen and (min-width: @screen-lg) {
            font-size: @LogoTypeDesktopSize;
        }

        &:extend([class^='frg-icon-'],
            [class*=' frg-icon-'] all);
        &:extend(.frg-icon-thule-logo-only-text all);

        &::before {
            content: "\e924";
        }
    }

    i.logotype-cl {
        font-size: @LogoTypeMobileSizeCL;

        @media screen and (min-width: @screen-lg) {
            font-size: @LogoTypeDesktopSizeCL;
        }

        &:extend([class^='cl-icon-'],
            [class*=' cl-icon-'] all);
        &:extend(.cl-icon-logo40-grey all);
    }
}