@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '/Foundation/Styling/code/thule/global/typography.less';
@import '@{variablesLocation}';
@import './Logo.less';
@import './PrimaryDesktopNavigation.less';
@import './QuickLinks.less';
@import './Search.less';
@import './SearchOverlay.less';
@import './Navigation.less';
@import './LinkSectionList.less';
@import './Klevu/QuickSearch.less';
@import './Klevu/SearchField.less';

@helloBarHeight: 4.4rem;

:root {
  --siteHeader-height: 7rem;
  --sitePadding-inline: 2rem;

  @media screen and (min-width: @screen-lg) {
    --siteHeader-height: 8.2rem;
    --sitePadding-inline: calc(100vw * 0.03);
  }
}

#REACT_siteHeader,
.siteHeader_wrapper,
.siteHeader {
  height: var(--siteHeader-height);
}

.sitePadding {
  padding-inline: var(--sitePadding-inline) !important;
}

.siteHeader_wrapper {
  border-bottom: 0.1rem solid @Gray20;
  max-height: var(--siteHeader-height);
  background-color: white;
  width: 100%;
  z-index: 2;

  .siteHeader_hidden {
    position: absolute;
    height: 0;
    pointer-events: none;
    opacity: 0;
  }
  .siteHeader_visible {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }

  .backdrop::after {
    content: '';
    background-color: #000;
    width: 100vw;
    height: 100vh;
    top: var(--siteHeader-height);
    left: 0;
    opacity: 0.3;
    z-index: 2;
    position: absolute;
    max-width: 1920px;

    @media screen and (min-width: 1920px) {
      margin-left: calc((100vw - 1920px) / 2);
    }
  }

  &.collapsed {
    position: fixed;
    top: 0;
  }

  &:not(.sticky).idle {
    transform: translateY(-100%);
    transition: transform 0.4s ease;
  }

  .layout__content {
    min-height: unset;
  }

  .siteHeader {
    display: flex;
    align-items: center;

    .rightContent {
      display: flex;
      width: 100%;
      justify-content: end;
      padding-left: @spacer6;
    }

    .navigationOverlay {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 3;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      scrollbar-width: none;
      width: 100%;
      max-width: calc(@screen-xxxxl / 3);
      padding-top: 3.3rem;
      background-color: white;
      transform: translateX(-100%);
      transition:
        transform 0.5s ease,
        opacity 0.3s ease;
      opacity: 0;

      &.active {
        transform: translateX(0);
        opacity: 1;
      }

      img {
        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }
      }

      .menuFirstLevel,
      .menuSecondaryLevel,
      .menuTertiaryLevel {
        &.siteHeader_hidden {
          opacity: 0;
        }

        &:not(.siteHeader_hidden) {
          opacity: 1;
          transition: opacity 0.3s ease-in;
        }
      }

      top: var(--siteHeader-height);

      @media screen and (min-width: @screen-md) {
        width: 90%;
      }

      @media screen and (min-width: @screen-lg) {
        width: calc(100% / 3);
        .menuQuaternaryLevel {
          opacity: 0;
          left: 100%;
        }
      }

      @media screen and (min-width: @screen-xxxxl) {
        margin-left: calc((100vw - @screen-xxxxl) / 2);
      }

      .menuTertiaryLevel {
        li::before {
          background-color: transparent;
          content: '';
          position: absolute;
          top: 0;
          left: calc(-1 * var(--sitePadding-inline));
          width: calc(100% + var(--sitePadding-inline) * 2);
          height: 100%;
          z-index: -1;
        }
      }
      &.menuLevel-4 {
        @media screen and (max-width: @screen-md-max) {
          .promotion,
          .menuTertiaryLevel {
            display: none;
          }
        }

        @media screen and (min-width: @screen-lg) {
          width: calc((100% / 3) * 2);
          max-width: calc((@screen-xxxxl / 3) * 2);

          .menuTertiaryLevel,
          .promotion,
          .navigationActionBar {
            width: calc(100vw / 3);
            max-width: calc(@screen-xxxxl / 3);
          }

          .menuTertiaryLevel {
            li.active {
              position: relative;

              &::before {
                background-color: @SoftSand25;
                transition: background-color 0.5s ease;
              }
            }
          }

          .menuQuaternaryLevel {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 50%;
            transition: opacity 0.5s ease;
            margin-top: 0;
            padding-top: 3.3rem;
            padding-bottom: 0;
            opacity: 1;
            background-color: @SoftSand25;
          }
        }
      }
    }
  }

  &.hasHelloBar {
    .navigationOverlay,
    .backdrop::after {
      top: calc(var(--siteHeader-height) + @helloBarHeight);
    }
  }
}

.siteHeader_wrapper.sticky {
  position: fixed;
  top: 0;
  transition: transform 0.4s ease;
  z-index: 3;

  &:not(.idle) {
    transform: translateY(0);
  }

  .navigationOverlay,
  .backdrop::after {
    top: var(--siteHeader-height);
  }

  .navigationOverlay {
    &.active {
      position: fixed;
    }
  }
}

.siteHeader_search {
  #inputSearch::-webkit-search-cancel-button {
    position: relative;
    right: -10px;
    -webkit-appearance: none;
    background-color: #181818;
    display: inline-block;
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    mask: url('../../../../../../../Foundation/Styling/code/thule/global/icons/x.svg') no-repeat center;
    -webkit-mask: url('../../../../../../../Foundation/Styling/code/thule/global/icons/x.svg') no-repeat center;
  }
}
