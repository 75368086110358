@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';
.searchOverlay {
    position: absolute;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 2;
    padding: 1rem @spacer6 0 @spacer6;

    @media screen and (max-width: @screen-md-max) {
      overflow-y: auto;
      position: fixed;
    }

    .search__border {
      @media screen and (min-width: @screen-lg) {
        border-top: 0.1rem solid @Gray20;
      }
    }

    .search__result--product {
      padding-bottom: @spacer11;

      @media screen and (min-width: @screen-lg) and (max-width: @screen-lg-max) {
        padding-left: @spacer7;
      }

      @media screen and (min-width: @screen-lg) {
        padding-right: @spacer7;
      }

      article {
        position: relative;

        @media screen and (min-width: @screen-lg) {
          width: calc(25% - 22px);

          &:not(:last-child) {
            margin-right: @spacer7;
          }
        }

        .extranet-search-product {
          .product__text {
            margin-left: 0 !important;
          }
        }

        .search__product {
          @media screen and (max-width: @screen-md-max) {
            margin-bottom: @spacer4;
          }

          @media screen and (min-width: @screen-lg) {
            flex-direction: column;
          }

          .product__text {
            margin-left: @spacer4;

            @media screen and (min-width: @screen-lg) {
              margin-left: 0;
            }
          }

          .product__imageContainer {
            @media screen and (max-width: @screen-xs-max) {
              min-width: 30%;
            }

            @media screen and (max-width: @screen-md-max) {
              width: 30%;
              max-width: 180px;
            }

            img {
              width: 100%;
              aspect-ratio: 1 / 1;
              object-fit: contain;
              background-color: @Gray10;
            }
          }

        //   .thule-site:not(.caselogic-site) {
        //     #thule-header {
        //       .search__product {
        //         img {
        //           background-color: @Gray10;
        //         }
        //       }
        //     }
        //   }

          

          .add-to-cart-button-react {
            flex-direction: column;

            .quantity-price-container,
            .price-container,
            .btn-container {
              margin: 0;
            }

            .quantity-price-container {
              margin-bottom: @spacer3;
            }

            .price {
              margin-bottom: @spacer3;
            }

            .rrp {
              margin-bottom: 0;
            }

            .btn-container {
              align-items: flex-start;

              button {
                margin-bottom: @spacer1;
              }

              .stock-status {
                margin-bottom: @spacer2;
              }
            }

            .big-spinner {
              font-size: 2.5rem;
            }
          }
        }
      }
    }

    @media screen and (min-width: @screen-lg) {
      padding: 0;
      min-height: 1rem;
      height: unset;

      .searchOverlayDrop::after {
        content: '';
        background-color: @PitchBlack;
        padding-left: 100vw;
        padding-bottom: 100vh;
        opacity: 0.3;
        position: absolute;
      }
    }

    form {
      flex: 1;
    }

    .searchInputContainer__wrapper {
      height: 7rem;

      @media screen and (min-width: @screen-lg) {
        height: 8.2rem;
      }

      .searchInputContainer {
        border-bottom: 0.1rem @Gray60 solid;
        align-items: center;
        padding: @spacer3;
        display: flex;
        width: 100%;

        @media screen and (max-width: @screen-md-max) {
          height: 4.8rem;
        }
      }
    }

    input {
      border: none;
      outline: none;
      background-color: transparent;

      &::placeholder {
        color: @Gray60;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: @Gray60;
      }

      &::-ms-input-placeholder {
        color: @Gray60;
      }
    }
  }