.quick-search {
  .search-btn {
    display: flex;
    position: relative;
    align-items: center;
    line-height: 0;
    padding: 0 1rem;
    cursor: pointer;
  }
}
.quick-search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 9999999999;
  display: grid;
  grid-template-columns: 1fr min(100%, 144rem) 1fr;
  grid-template-rows: max-content;
  width: 100%;

  @media screen and (min-width: @screen-lg) {
    bottom: unset;
    height: 95rem;
  }

  @media screen and (min-width: @screen-xxl) {
    height: 70rem;
  }

  .quick-search-bar {
    min-height: 48px;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    grid-column: 2 / 3;
    justify-content: center;

    @media screen and (min-width: @screen-lg) {
      grid-template-columns: 1fr min(100%, 81.8rem) 1fr;
      grid-column: 2 / 3;
    }

    .logo {
      display: none;
      visibility: hidden;
      margin-right: 0;

      @media screen and (min-width: 1040px) {
        display: flex;
        visibility: visible;
      }
    }

    .thule-input {
      width: min(100%, 99.1rem);
      max-height: 7rem;
      grid-column: 1 / 2;

      @media screen and (min-width: @screen-lg) {
        max-height: @spacer10;
        width: min(100%, 81.8rem);

        grid-column: 2 / 3;
      }

      .input {
        height: 7rem;

        @media screen and (min-width: @screen-lg) {
          max-height: @spacer10;
        }
      }
    }
  }

  .quick-search-results-container {
    grid-column: 2 / 3;
    display: grid;
    grid-template-columns: 1fr min(100%, 99.1rem) 1fr;
    overflow-y: auto;

    @media screen and (min-width: @screen-lg) {
      grid-template-columns: 1fr min(100%, 81.8rem) 1fr;
    }

    .quick-search-results {
      grid-column: 1 / 3;
      overflow-y: auto;

      @media screen and (min-width: @screen-lg) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-auto-rows: max-content;
        max-width: 120rem;
        column-gap: @spacer6;
        grid-column: 2 / 3;
      }

      @media screen and (min-width: @screen-xxl) {
        display: grid;
        grid-auto-rows: max-content;
        max-width: 144rem;
        width: 100%;
        column-gap: @spacer6;
        grid-template-columns: 1fr min(100%, 81.8rem) 1fr;
        grid-column: 1/4;
      }

      .section-sub-header {
        color: @Gray60;
      }

      .products {
        flex-direction: column;
        gap: @spacer4;

        animation: scrolling forwards;
        animation-timeline: scroll();

        overflow-y: auto;

        @media (min-width: @screen-lg) {
          flex-direction: row;
          flex-wrap: wrap;
        }

        @media (min-width: @screen-xxl) {
          flex-wrap: nowrap;
          column-gap: @spacer6;
        }

        article {
          @media (min-width: @screen-lg) {
            max-width: calc((100% / 2) - @spacer5 + (@spacer5 / 2));
          }

          @media (min-width: @screen-xxl) {
            max-width: calc((100% / 4) - @spacer6 + (@spacer6 / 4));
          }

          .quick_search__product {
            @media (max-width: @screen-md-max) {
              display: flex;
              flex-direction: row;
              gap: @spacer5;

              img {
                width: 9rem;
                height: 9rem;
                max-width: 9rem;
              }
            }

            .product__imageContainer {
              width: 9rem;
              height: 9rem;
              background-color: @Gray10;
              aspect-ratio: 1;

              @media (min-width: @screen-lg) {
                width: calc((532px / 2) - @spacer5 + (@spacer5 / 2));
                height: auto;
              }

              @media (min-width: @screen-xxl) {
                width: calc((818px / 4) - @spacer6 + (@spacer6 / 4));
                height: auto;
              }
            }

            img {
              background-color: @Gray10;
            }
          }
        }
      }

      .quick-search-links {
        display: flex;
        flex-direction: column;
        gap: @spacer8;

        @media screen and (min-width: @screen-lg) {
          gap: @spacer9;
        }

        &:has(.popular-section) {
          grid-column: 1 / 2;

          @media screen and (min-width: @screen-xxl) {
            grid-column: 2 / 3;
          }
        }

        .suggestions-section,
        .category-section,
        .popular-section {
          li {
            cursor: pointer;
          }
        }

        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            color: @Gray60;
            padding-bottom: @spacer5;

            &:last-child {
              padding-bottom: @spacer7;
            }

            b {
              color: @Black;
            }
          }
        }
      }
    }
  }
}

.quick-search-overlay__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999998;
}

@keyframes scrolling {
  0%,
  to {
    padding-right: 2rem;
  }
}
