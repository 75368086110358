.quick-search-overlay {
  i.le-icon-x {
    position: absolute;
    top: calc(50% - 1.2rem);
    right: 1.2rem;

    @media (min-width: @screen-lg) {
      position: absolute;
      top: calc(50% - 1.2rem);
      right: -4.2rem;
    }
  }

  p.clear {
    position: absolute;
    top: calc(50% - 1.2rem);
    right: 1.2rem;
    cursor: pointer;
  }

  .close {
    cursor: pointer;
  }
}
